'use client';

import { m } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { PropsWithChildren, useLayoutEffect } from 'react';

const variants = {
    hidden: { opacity: 0, y: 20 },
    enter: { opacity: 1, y: 0 }
};

export default function BaseTemplateComponent (props: PropsWithChildren) {
    const pathname = usePathname();

    useLayoutEffect(() => {
        onAnimationStart();
    }, []);

    const onAnimationStart = () => {
        document.body.style.overflow = 'hidden';
    };

    const onAnimationComplete = () => {
        document.body.style.overflow = 'auto';
    };

    return (
        <m.div
            key={pathname}
            variants={variants}
            onAnimationStart={onAnimationStart}
            onAnimationComplete={onAnimationComplete}
            initial="hidden"
            animate="enter"
            transition={{ type: 'linear', duration: .5 }}
            style={{
                flexGrow: '1',
                display: 'flex',
                maxWidth: '100%',
                flexDirection: 'column'
            }}
        >
            {props.children}
        </m.div>
    );
}
